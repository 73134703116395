import { useState, useEffect } from "react";
import { FaAsterisk } from "react-icons/fa";
import { Select } from "../../SharedModule/components/Select";
import {
  selectProductsMetaLoaded,
  selectRecurringOptions,
} from "../redux/reducers/metaProducts.reducer";
import { useSelector } from "react-redux";
import { useModal } from "../../SharedModule/hooks/useModal";
import { useEditProduct } from "../hooks/useEditProduct";

export const ModalLayout = ({
  title,
  btnLabel,
  btnCancelLabel,
  btnCancel,
  product,
}: any) => {
  const loadData = () => {
    setProjectName(product.name);
    changeClient(actualClient);
    let response: any = [];
    product.projects.forEach((project: any) => {
      let actual = recurringOptions.projectsProducts?.find((proj) => {
        return (
          product.account.id === proj.client &&
          proj.value === project.externalId
        );
      });
      if (actual) {
        response.push(actual);
      } else {
        // when project is inactive, only shows on edit modal appears. Only choice is to keep or delete, so client isn't important
        response.push({
          client: "",
          label: `${project.externalId} - (INACTIVE) ${project.name}`,
          value: project.externalId,
        });
      }
    });
    handleProject(response);
  };

  useEffect(() => {
    if (editMode) {
      loadData();
    }
  }, []);

  let editMode = product.id !== 0;

  const recurringOptions = useSelector(selectRecurringOptions);
  const metaLoaded = useSelector(selectProductsMetaLoaded);

  const setInactiveActualClient = () => {
    actualClient = {
      value: product.account.id,
      label: product.account.name + " (x)",
    };
  };

  let actualClient = recurringOptions.clientsProducts?.find(
    (elem) => elem.value === product.account.id.toString()
  );
  // inactive client
  if (!actualClient) {
    setInactiveActualClient();
  }

  const [isSelectProjectsDisabled, setIsSelectProjectsDisabled] =
    useState(true);
  const [actualProjects, setActualProjects] = useState([]);

  const [projectName, setProjectName] = useState(editMode ? product.name : "");
  const [client, setClient] = useState(
    editMode ? actualClient : { value: "0", label: "Select Client" }
  );
  const [projects, setProjects] = useState<any[]>([]);

  const { showModal, closeModal, Modal, ...modalProps } = useModal();

  const editProduct = useEditProduct(showModal, closeModal, editMode);

  const handleChange = (evt: any) => {
    const value = evt.target.value;
    setProjectName(value);
  };

  const handleProject = (project: any) => {
    setProjects(project);
  };

  const changeClient = (client: any) => {
    setClient(client);
    setIsSelectProjectsDisabled(false);
    let filtered: any = recurringOptions.projectsProducts?.filter((proj) => {
      return proj.client.toString() === client.value;
    });
    setActualProjects(filtered);
    handleProject(null);
  };

  const handleProduct = async () => {
    let projectList: any = [];

    if (projects && projects.length > 0) {
      projectList = projects.map((elem) => elem.value);
    }

    await editProduct({
      id: product.id,
      projectName: projectName,
      client: client?.value,
      projectList: projectList,
    });
    btnCancel(true);
  };

  return (
    <>
      <Modal closeModal={closeModal} {...modalProps} />
      {!editMode && <h4 className="text-center text-capitalize">{title}</h4>}
      {editMode && (
        <h4 className="text-center text-capitalize">Edit Product</h4>
      )}
      <hr style={{ width: "100%" }} />
      <div className="d-flex mt-2 flex-column mb-2">
        <label className="mb-1">
          Product Name{" "}
          <span className="required">
            <FaAsterisk size={"6px"} />
          </span>
        </label>
        <input
          type="text"
          name="projectName"
          className="form-control form-control-sm"
          value={projectName}
          onChange={(event) => handleChange(event)}
        />
      </div>
      <div className="d-flex mt-2 flex-column mb-2">
        <label className="mb-1">
          Client{" "}
          <span className="required">
            <FaAsterisk size={"6px"} />
          </span>
        </label>
        <Select
          placeholder="Select Client"
          className="mb-2"
          options={recurringOptions.clientsProducts}
          isLoading={!metaLoaded}
          value={{
            value: client?.value,
            label: client?.label,
          }}
          onChange={(client: any) => {
            changeClient(client);
          }}
          isDisabled={!product.canEditProductClient}
        />
      </div>
      <div className="d-flex mt-2 flex-column mb-2">
        <label className="mb-1">Projects</label>
        <Select
          isMulti
          placeholder="Select Project"
          className="mb-2"
          options={actualProjects}
          isLoading={!metaLoaded}
          value={projects}
          onChange={(project: any) => {
            handleProject(project);
          }}
          isDisabled={isSelectProjectsDisabled}
        />
      </div>
      <hr style={{ width: "100%" }} />
      <div className="d-flex mt-2">
        <button
          className="btn button-secondary w-100"
          onClick={() => btnCancel(false)}
        >
          {btnCancelLabel}
        </button>
        <button
          className="btn btn-primary w-100 ms-2"
          disabled={projectName.length === 0 || client?.value === "0"}
          onClick={() => handleProduct()}
        >
          {!editMode ? btnLabel : "Edit"}
        </button>
      </div>
    </>
  );
};
