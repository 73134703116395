import { useEffect, useRef, useState } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { BiInfoCircle, BiLeftArrowAlt } from "react-icons/bi";
import { RiShareBoxFill } from "react-icons/ri";
import { FiMail, FiPhoneCall } from "react-icons/fi";
import {
  ROUTE_DELETE_PRODUCT_PAGE,
  ROUTE_PRODUCT_KEYCONTACTS,
  ROUTE_PRODUCT_KEYCONTACTS_CONFIDENCE_HISTORY,
  ROUTE_PRODUCT_KEYCONTACTS_DETAILS,
} from "../../SharedModule/utils/constants";
import {
  getAdvocacyNumber,
  getUserNameToCompleteSelect,
  getTrendingName,
  getRelationshipTrend,
} from "../utils/keyContactUtils";
import { Calendar } from "../../SharedModule/components/Calendar";
import { Slider } from "@mui/material";
import {
  setAdvocacyDescription,
  setAdvocacyTexts,
} from "./../utils/contact-advocacy.factory";
import { Select } from "../../SharedModule/components/Select";
import { selectUsers } from "../../BillingModule/redux/reducers/metaBilling.reducer";
import { useDispatch, useSelector } from "react-redux";
import { KeyContactsService } from "./../services/keyContacts";
import { useDeleteKeyContact } from "../hooks/useDeleteKeyContact";
import { useModal } from "../../SharedModule/hooks/useModal";
import { useKeyContacts } from "../hooks/useKeyContacts";
import {
  AdvocacyLevel,
  KeyContactDetail,
  KeyContactDetailForm,
  StakeholderConfidence,
} from "../types/types";
import { Spinner } from "../../SharedModule/components/Spinner";
import {
  selectAuth,
  selectUserRoles,
} from "../../SharedModule/redux/reducers/auth.reducer";
import { addToast } from "../../SharedModule/redux/reducers/ui.reducer";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { setActiveTab } from "../../SharedModule/redux/reducers/uiShared.reducer";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useProducts } from "../hooks/useProducts";
import eventHandler from "../../SharedModule/utils/eventHandler";
import { ModalPortal } from "../../SharedModule/components/ModalPortal";
import { ModalImage } from "../../SharedModule/components/ModalImage";
import Footer from "../../SharedModule/components/Footer";

const today: Date = new Date();
today.setHours(0);
today.setMinutes(0);
today.setSeconds(0);

export const KeyContactDetailPage = () => {
  let { productId, contactId }: any = useParams();

  const { trackPageView } = useMatomo();

  const auth = useSelector(selectAuth);
  const isLogged = auth.isAuthenticated;

  const [loading, setLoading] = useState(true);

  const [formTouched, setFormTouched] = useState(false);

  // Modal config
  const modalRef = useRef();
  const showModalProfiles = () => {
    (modalRef.current as any).show(true);
  };
  const closeModalProfiles = () => {
    (modalRef.current as any).show(false);
  };

  const dispatch = useDispatch();

  // keep this import. We use location.pathname as param in useEffect to re render this component after "save & view next"
  const location = useLocation();

  const [
    isUserPrimaryRelationshipSteward,
    setIsUserPrimaryRelationshipSteward,
  ] = useState(false);

  const loadData = async () => {
    let actualKeyContactData: KeyContactDetail =
      await KeyContactsService.getKeyContactDetails(productId, contactId)
        .then((data) => {
          return data;
        })
        .catch((error) => {
          dispatch(
            addToast({
              mode: "error",
              message: error.data.errors[1] ?? error.data.errors[0],
            })
          );
          navigate(ROUTE_PRODUCT_KEYCONTACTS.replace(":productId", productId));
          return error.data.succeeded;
        });

    if (actualKeyContactData) {
      setIsUserPrimaryRelationshipSteward(
        myself.value === actualKeyContactData.primaryRelationshipStewardId
      );

      setKeyContact(actualKeyContactData);
      // set form data with info from BE
      setKeyContactForm({
        primaryRelationshipStewardId:
          actualKeyContactData.primaryRelationshipStewardId
            ? actualKeyContactData.primaryRelationshipStewardId
            : myself.value,
        behaviorsAndConcerns: actualKeyContactData.relationshipGrowthPlan
          ? actualKeyContactData.relationshipGrowthPlan.behaviorsAndConcerns
          : "",
        relationshipGrowthPlan: actualKeyContactData.relationshipGrowthPlan
          ? actualKeyContactData.relationshipGrowthPlan.growthPlan
          : "",
        relationshipLastVerified: actualKeyContactData.relationshipGrowthPlan
          ? actualKeyContactData.relationshipGrowthPlan.lastVerified
          : today,
        // ASUITE-2107: N/A in Database has a new id equal to 5 to allow save
        advocacyLevel: actualKeyContactData.advocacyLevel
          ? actualKeyContactData.advocacyLevel.level !== 5
            ? actualKeyContactData.advocacyLevel.level
            : 0
          : 0,
        advocacyTrending: actualKeyContactData.advocacyLevel
          ? actualKeyContactData.advocacyLevel.relationshipLevelTrendId
          : 3,
        advocacyLastVerified: actualKeyContactData.advocacyLevel
          ? actualKeyContactData.advocacyLevel.lastVerified
          : today,
        stakeholderConfidence: actualKeyContactData.stakeholderConfidence
          ? actualKeyContactData.stakeholderConfidence.confidenceValue
          : 0,
        stakeholderClientComment: actualKeyContactData.stakeholderConfidence
          ? actualKeyContactData.stakeholderConfidence.clientComment
          : "",
        stakeholderLastVerified: actualKeyContactData.stakeholderConfidence
          ? actualKeyContactData.stakeholderConfidence.lastVerified
          : today,
        stakeholderLastVerifiedBy: actualKeyContactData.stakeholderConfidence
          ? actualKeyContactData.stakeholderConfidence.lastVerifiedBy
          : myself.value,
      });

      if (actualKeyContactData.advocacyLevel) {
        setFirstStateAdvocacyLevel({
          level: actualKeyContactData.advocacyLevel.level,
          relationshipLevelTrendId:
            actualKeyContactData.advocacyLevel.relationshipLevelTrendId,
          lastVerified: actualKeyContactData.advocacyLevel.lastVerified,
        });
      }

      if (actualKeyContactData.stakeholderConfidence) {
        setFirstStateStakeholderConfidence({
          confidenceValue:
            actualKeyContactData.stakeholderConfidence.confidenceValue,
          clientComment:
            actualKeyContactData.stakeholderConfidence.clientComment,
          lastVerified: actualKeyContactData.stakeholderConfidence.lastVerified,
          lastVerifiedBy:
            actualKeyContactData.stakeholderConfidence.lastVerifiedBy,
        });
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    // matomo page tracker
    trackPageView({
      documentTitle: document.location.hostname + "/" + document.title,
    });

    dispatch(setActiveTab("keyContacts"));
    loadData();
  }, [location.pathname]);

  // Use this structure to set not editable data
  const [keyContact, setKeyContact] = useState<KeyContactDetail>(Object);
  // Form to edit key contact
  const [keyContactForm, setKeyContactForm] =
    useState<KeyContactDetailForm>(Object);
  // Form Validation
  const [isFormValid, setIsFormValid] = useState(false);
  // Set first state of Advocacy Level / Stakeholder Confidence
  // If new value is equals to this first value(slider), all data set at first moment values
  const [firstStateStakeholderConfidence, setFirstStateStakeholderConfidence] =
    useState<StakeholderConfidence>(Object);
  const [firstStateAdvocacyLevel, setFirstStateAdvocacyLevel] =
    useState<AdvocacyLevel>(Object);

  // Form fields touched
  const [formFields, setFormFields] = useState({
    relationshipGrowthPlan: false,
    advocacyLevel: false,
    stakeholderConfidence: false,
  });

  const navigate = useNavigate();

  let confidenceHistoryLink: string =
    ROUTE_PRODUCT_KEYCONTACTS_CONFIDENCE_HISTORY.replace(
      ":productId",
      productId
    ).replace(":contactId", contactId);

  const { keyContactList, keyContacts, trendingOptions, isLoadingData } =
    useKeyContacts(Number(productId));

  const users = useSelector(selectUsers);
  const { isLoadingExternalBilling } = useProducts();

  const { showModal, closeModal, Modal, ...modalProps } = useModal();

  const deleteKeyContact = useDeleteKeyContact(showModal, closeModal);

  // Set actual user id to apply on stakeholderLastVerifiedBy when any fields change
  const { activeUserId, activeUserName } = useSelector(selectUserRoles);
  let myself: any = {
    value: activeUserId,
    label: activeUserName,
  };

  // users without key contacts already added
  let actualUsersToAdd = [...users];

  // ASUITE-11:2119: endpoint error when product not exist
  if (!isLoadingData) {
    if (keyContactList && !keyContactList.errors) {
      keyContactList.forEach((elem: any) => {
        let keyContactAddedIndex = actualUsersToAdd.findIndex(
          (actual) => actual.value === elem.contactId
        );
        if (keyContactAddedIndex || keyContactAddedIndex === 0) {
          actualUsersToAdd.splice(keyContactAddedIndex, 1);
        }
      });
    } else if (keyContactList && keyContactList.errors) {
      navigate(ROUTE_DELETE_PRODUCT_PAGE);
    }
  }

  const [, setKeyContactRelationshipSteward] = useState(users[0]);
  // Stakeholder confidence
  const [, setLastVerifiedByUser] = useState(users[0]);

  const [, setKeyContactRelationshipLevelTrendId] = useState(
    trendingOptions[2]
  );

  const checkFormValid = () => {
    if (
      keyContactForm.stakeholderClientComment.length === 0 &&
      formFields.stakeholderConfidence
    ) {
      setIsFormValid(false);
    } else {
      setIsFormValid(true);
    }
  };

  const handleFormChange = (e: any, property: string) => {
    let actualValueToUpdate = typeof e === "object" ? e.target.value : e;
    let formValidAlreadyChecked = false;

    // Flag to know if there is one change on form, to avoid save data when "Save & View Next Contact" is clicked
    setFormTouched(true);

    // Validations
    // Primary Relationship StewardId
    if (property === "primaryRelationshipStewardId") {
      setKeyContactForm({
        ...keyContactForm,
        primaryRelationshipStewardId: actualValueToUpdate,
      });
    }
    // Behaviors And Concerns
    if (property === "behaviorsAndConcerns") {
      setKeyContactForm({
        ...keyContactForm,
        behaviorsAndConcerns: actualValueToUpdate,
        relationshipLastVerified: today,
      });
      setFormFields({
        ...formFields,
        relationshipGrowthPlan: true,
      });
    }
    // Relationship Growth Plan
    if (property === "relationshipGrowthPlan") {
      setKeyContactForm({
        ...keyContactForm,
        relationshipGrowthPlan: actualValueToUpdate,
        relationshipLastVerified: today,
      });
      setFormFields({
        ...formFields,
        relationshipGrowthPlan: true,
      });
    }
    if (property === "relationshipLastVerified") {
      setKeyContactForm({
        ...keyContactForm,
        relationshipLastVerified: actualValueToUpdate,
      });
      setFormFields({
        ...formFields,
        relationshipGrowthPlan: true,
      });
    }
    // Advocacy Level
    if (property === "advocacyLevel") {
      // check new value is equal from BE, we should set this field as "untouched"
      if (actualValueToUpdate === firstStateAdvocacyLevel.level) {
        setKeyContactForm({
          ...keyContactForm,
          advocacyLevel: firstStateAdvocacyLevel.level,
          advocacyTrending: firstStateAdvocacyLevel.relationshipLevelTrendId,
          advocacyLastVerified: firstStateAdvocacyLevel.lastVerified,
        });
        setFormFields({
          ...formFields,
          advocacyLevel: false,
        });
      } else {
        setKeyContactForm({
          ...keyContactForm,
          advocacyLevel: actualValueToUpdate,
          advocacyTrending: 3,
          advocacyLastVerified: today,
        });
        setFormFields({
          ...formFields,
          advocacyLevel: true,
        });
      }
    }
    // Relationship Level Trending
    if (property === "relationshipLevelTrendId") {
      setKeyContactForm({
        ...keyContactForm,
        advocacyTrending: actualValueToUpdate,
        advocacyLastVerified: today,
      });
      setFormFields({
        ...formFields,
        advocacyLevel: true,
      });
    }
    if (property === "advocacyLastVerified") {
      setKeyContactForm({
        ...keyContactForm,
        advocacyLastVerified: actualValueToUpdate,
      });
      setFormFields({
        ...formFields,
        advocacyLevel: true,
      });
    }
    // Stakeholder Confidence
    if (property === "stakeholderConfidence") {
      // check new value is equal from BE, we should set this field as "untouched"
      if (
        actualValueToUpdate === firstStateStakeholderConfidence.confidenceValue
      ) {
        setIsFormValid(true);
        setKeyContactForm({
          ...keyContactForm,
          stakeholderConfidence:
            firstStateStakeholderConfidence.confidenceValue,
          stakeholderClientComment:
            firstStateStakeholderConfidence.clientComment,
          stakeholderLastVerified: firstStateStakeholderConfidence.lastVerified,
          stakeholderLastVerifiedBy:
            firstStateStakeholderConfidence.lastVerifiedBy,
        });
        setLastVerifiedByUser({
          value: firstStateStakeholderConfidence.lastVerifiedBy,
          label: getUserNameToCompleteSelect(
            users,
            firstStateStakeholderConfidence.lastVerifiedBy
          ),
        });
        setFormFields({
          ...formFields,
          stakeholderConfidence: false,
        });
      } else {
        setFormFields({
          ...formFields,
          stakeholderConfidence: true,
        });
        setLastVerifiedByUser({
          value: myself.value,
          label: myself.label,
        });
        setKeyContactForm({
          ...keyContactForm,
          stakeholderConfidence: actualValueToUpdate,
          stakeholderLastVerified: today,
          stakeholderLastVerifiedBy: myself.value,
        });
        // check if form is valid when changes level
        if (keyContactForm.stakeholderClientComment.length === 0) {
          setIsFormValid(false);
          formValidAlreadyChecked = true;
        }
      }
    }
    if (property === "stakeholderClientComment") {
      setFormFields({
        ...formFields,
        stakeholderConfidence: true,
      });
      setKeyContactForm({
        ...keyContactForm,
        stakeholderClientComment: actualValueToUpdate,
        stakeholderLastVerified: today,
        stakeholderLastVerifiedBy: myself.value,
      });
      // check if form is valid when changes comment
      if (actualValueToUpdate.length === 0) {
        setIsFormValid(false);
        formValidAlreadyChecked = true;
      } else {
        setIsFormValid(true);
        formValidAlreadyChecked = true;
      }
    }
    if (
      property === "stakeholderLastVerified" ||
      property === "stakeholderLastVerifiedBy"
    ) {
      setFormFields({
        ...formFields,
        stakeholderConfidence: true,
      });
      if (property === "stakeholderLastVerified") {
        setKeyContactForm({
          ...keyContactForm,
          stakeholderLastVerified: actualValueToUpdate,
        });
      } else {
        setKeyContactForm({
          ...keyContactForm,
          stakeholderLastVerifiedBy: actualValueToUpdate,
        });
      }
      // check if form is valid when changes last verified
      if (keyContactForm.stakeholderClientComment.length === 0) {
        setIsFormValid(false);
        formValidAlreadyChecked = true;
      }
    }
    // check if form is valid
    if (!formValidAlreadyChecked) {
      checkFormValid();
    }
  };

  const customNavigate = (viewNextKeyContact: boolean) => {
    // Go to next key contact in list
    if (keyContacts && viewNextKeyContact) {
      let actualKeyContactIndex = keyContacts.findIndex(
        (elem) => Number(contactId) === elem.keyContactId
      );
      // if last key contact in element list, go to key contact list
      if (actualKeyContactIndex === keyContacts.length - 1) {
        navigate(ROUTE_PRODUCT_KEYCONTACTS.replace(":productId", productId));
      } else {
        // set form as untouched to disabled 'save changes' button
        setFormTouched(false);
        navigate(
          ROUTE_PRODUCT_KEYCONTACTS_DETAILS.replace(
            ":productId",
            productId
          ).replace(
            ":contactId",
            keyContacts[actualKeyContactIndex + 1].keyContactId.toString()
          )
        );
      }
    } else {
      // go to key contact list
      navigate(ROUTE_PRODUCT_KEYCONTACTS.replace(":productId", productId));
    }
  };

  const handleConfirmContact = async (
    event: any,
    viewNextKeyContact: boolean
  ) => {
    event.preventDefault();

    // When user click "Save & View Next Contact" without touching form
    if (!formTouched) {
      customNavigate(viewNextKeyContact);
      return;
    }

    let body: any = {
      primaryRelationshipStewardId: keyContactForm.primaryRelationshipStewardId,
      relationshipGrowthPlan: formFields.relationshipGrowthPlan
        ? {
            growthPlan: keyContactForm.relationshipGrowthPlan,
            behaviorsAndConcerns: keyContactForm.behaviorsAndConcerns,
            lastVerified: keyContactForm.relationshipLastVerified,
          }
        : null,
      advocacyLevel: formFields.advocacyLevel
        ? {
            // ASUITE-2107: N/A in Database has a new id equal to 5 to allow save
            level:
              keyContactForm.advocacyLevel === 0
                ? 5
                : keyContactForm.advocacyLevel,
            relationshipLevelTrendId: keyContactForm.advocacyTrending,
            lastVerified: keyContactForm.advocacyLastVerified,
          }
        : null,
      stakeholderConfidence: formFields.stakeholderConfidence
        ? {
            confidenceValue: keyContactForm.stakeholderConfidence,
            clientComment: keyContactForm.stakeholderClientComment,
            lastVerified: keyContactForm.stakeholderLastVerified,
            lastVerifiedBy: keyContactForm.stakeholderLastVerifiedBy,
          }
        : null,
    };

    // service to update
    let result = await KeyContactsService.editKeyContact(
      Number(productId),
      Number(contactId),
      body
    );
    if (result === "Key Contact Updated Successfully") {
      dispatch(addToast({ mode: "success", message: result }));
      customNavigate(viewNextKeyContact);
    } else {
      dispatch(addToast({ mode: "error", message: "Something went wrong" }));
    }
    eventHandler.dispatch("toast action", { isClosed: false });
  };

  const handleRemoveContact = async (event: any) => {
    event.preventDefault();

    let response = await deleteKeyContact({
      productId: productId,
      keyContactId: contactId,
      name: `${keyContact.contact.firstName} ${keyContact.contact.lastName}`,
    });
    if (response) {
      navigate(ROUTE_PRODUCT_KEYCONTACTS.replace(":productId", productId));
    }
  };

  // logic to be able to edit details: isProductActive > isContentEditable
  // ASUITE11-2178: if isContentEditable could edit normally => isUserPrimaryRelationshipSteward could edit without remove
  const isNotEditable = () => {
    return !keyContact.isProductActive || !isLogged
      ? true
      : !keyContact.isContentEditable && !isUserPrimaryRelationshipSteward;
  };

  return (
    <>
      <Modal closeModal={closeModal} {...modalProps} />
      <ModalPortal ref={modalRef}>
        <ModalImage btnCancel={closeModalProfiles} type="formModal" />
      </ModalPortal>
      <>
        {loading && (
          <Spinner
            style={{
              marginLeft: "40%",
              marginTop: "15%",
            }}
          />
        )}
        {!loading && (
          <div className="content" style={{ marginTop: "-15px" }}>
            <form>
              <div className="sticky-row">
                <div
                  className="button-header"
                  style={{ width: "100%", padding: "15px" }}
                >
                  <Link
                    className="back-button-container d-flex text-decoration-none"
                    to={ROUTE_PRODUCT_KEYCONTACTS.replace(
                      ":productId",
                      productId
                    )}
                  >
                    <BiLeftArrowAlt className="back-button-arrow mt-1" />
                    <span className="back-button-text mt-1">
                      Back to Contacts
                    </span>
                    {!keyContact.isProductActive && (
                      <button className="btn-status">Status: Inactive</button>
                    )}
                  </Link>
                  {!isNotEditable() && (
                    <div>
                      {keyContact.contact.active && (
                        <button
                          className="btn btn-primary me-3"
                          type="submit"
                          disabled={
                            !isFormValid || (isFormValid && !formTouched)
                          }
                          onClick={(e) => handleConfirmContact(e, false)}
                        >
                          Save Changes
                        </button>
                      )}
                      {keyContact.contact.active && (
                        <button
                          className="btn button-secondary me-3"
                          type="submit"
                          onClick={(e) => handleConfirmContact(e, true)}
                          disabled={formTouched && !isFormValid}
                        >
                          Save & View Next Contact
                        </button>
                      )}
                      {keyContact.isContentEditable && (
                        <button
                          className="btn button-secondary-danger"
                          onClick={(e) => handleRemoveContact(e)}
                        >
                          Remove Contact
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>

              {/* Contact Detail right panel */}
              <div className="block block-sticky me-3 mt-4">
                <div className="block-header">
                  <h6 style={{ fontWeight: "bold" }}>Contact Details</h6>
                  {!keyContact.contact.externalID && (
                    <div className="d-flex justify-content-between">
                      <a
                        className="link-text w-100"
                        target="_blank"
                        rel="noreferrer"
                        href={`${process.env.REACT_APP_ASUITE_BASE_URI}/busdev/busdevcontacts.asp?contacts=${keyContact.contact.id}&company=${keyContact.contact.companyId}`}
                      >
                        <span className="me-1">
                          Edit Contact Fields in Business Development
                        </span>
                        <RiShareBoxFill />
                      </a>
                    </div>
                  )}
                  {!!keyContact.contact.externalID && (
                    <div className="d-flex justify-content-between">
                      <a
                        className="link-text w-100"
                        target="_blank"
                        rel="noreferrer"
                        href={`${process.env.REACT_APP_HUBSPOT_BASE_URI}/contacts/${process.env.REACT_APP_HUBSPOT_ACCOUNT_ID}/contact/${keyContact.contact.externalID}`}
                      >
                        <span className="me-1">
                          Edit Contact Fields in HubSpot
                        </span>
                        <RiShareBoxFill />
                      </a>
                    </div>
                  )}
                </div>
                <div className="block-header">
                  <div className="key-contact">
                    <div className="key-contact-name d-flex mb-2">
                      <div>
                        <h5>
                          {`${keyContact.contact.firstName} ${keyContact.contact.lastName}`}{" "}
                          {!keyContact.contact.active && (
                            <span>
                              <i> - (Inactive)</i>
                            </span>
                          )}
                        </h5>
                        {keyContact.contact.title && (
                          <div className="key-contact-name grayMedium">
                            <span>{keyContact.contact.title}</span>
                          </div>
                        )}
                      </div>
                    </div>
                    {keyContact.contact.email && (
                      <div className="d-flex mt-3">
                        <FiMail className="info-icon ms-2" />
                        <div className="grayMedium">
                          {keyContact.contact.email}
                        </div>
                      </div>
                    )}
                    {keyContact.contact.phoneNumber && (
                      <div className="d-flex mt-3">
                        <FiPhoneCall className="info-icon ms-2" />
                        <div className="grayMedium">
                          {keyContact.contact.phoneNumber}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="block-header p-3"></div>
                  <div className="mt-3">
                    <div className="position-relative">
                      <label className="form-label grayMedium">
                        Primary Relationship Steward
                      </label>
                      <Select
                        placeholder="Select Name"
                        className="mb-3"
                        options={users}
                        isLoading={isLoadingExternalBilling}
                        value={{
                          value: keyContactForm.primaryRelationshipStewardId,
                          label: getUserNameToCompleteSelect(
                            users,
                            keyContactForm.primaryRelationshipStewardId
                          ),
                        }}
                        onChange={(kcr: any) => {
                          setKeyContactRelationshipSteward({
                            value: kcr.value,
                            label: kcr.label,
                          });
                          handleFormChange(
                            kcr.value,
                            "primaryRelationshipStewardId"
                          );
                        }}
                        isDisabled={isNotEditable()}
                      />
                    </div>
                  </div>
                  <div className="d-flex mt-4">
                    <div className="grayMedium w-40">Roles</div>
                    <div className="d-flex flex-column">
                      {keyContact.contact.roles.map((role: string) => (
                        <span
                          key={keyContact.contact.id + role}
                          className="key-contact-role-block me-1 mb-1 text-center"
                        >
                          {role}
                        </span>
                      ))}
                    </div>
                  </div>
                  <div className="d-flex mt-4">
                    <div className="grayMedium w-40">
                      DISC profile{" "}
                      <BiInfoCircle
                        className="disc-icon"
                        onClick={showModalProfiles}
                      />
                    </div>
                    {keyContact.contact.discProfile && (
                      <span className="key-contact-profile-block">
                        {keyContact.contact.discProfile}
                      </span>
                    )}
                  </div>
                  <div className="d-flex mt-4">
                    <div className="grayMedium w-40">Relationship status</div>
                    {keyContact.advocacyLevel &&
                      keyContact.advocacyLevel.level < 5 && (
                        <span
                          className={`key-contact-role-block ${getAdvocacyNumber(
                            keyContact.advocacyLevel
                              ? keyContact.advocacyLevel.level
                              : 0
                          )}`}
                        >
                          {getAdvocacyNumber(
                            keyContact.advocacyLevel
                              ? keyContact.advocacyLevel.level
                              : 0
                          )}
                          <span className="trend-arrow">
                            {getRelationshipTrend(
                              keyContact.advocacyLevel.relationshipLevelTrendId
                            )}
                          </span>
                        </span>
                      )}
                  </div>
                </div>
              </div>

              {/* Advocacy Level => Relationship Level */}
              <div className="block block me-3 mt-4">
                <div className="block-header block-header-with-background">
                  <h6 style={{ fontWeight: "bold" }}>Relationship Level</h6>
                  <div>
                    This is the latest advocacy level exhibited by this contact.
                  </div>
                </div>
                <div className="block-body">
                  <div className="d-flex justify-content-between">
                    <div
                      className="d-flex flex-column"
                      style={{ width: "65%" }}
                    >
                      <label className="form-label gray-label">LEVEL</label>
                      <Slider
                        key="advocacy-slider"
                        disabled={isNotEditable()}
                        value={
                          keyContactForm.advocacyLevel
                            ? keyContactForm.advocacyLevel
                            : 0
                        }
                        step={1}
                        min={0}
                        max={4}
                        onChange={(e) => handleFormChange(e, "advocacyLevel")}
                      />
                      <div className="slider-indicators">
                        <span>N/A</span>
                        <span>Below Trust</span>
                        <span>Trust</span>
                        <span>Loyalty</span>
                        <span>Advocacy</span>
                      </div>
                    </div>
                    <div
                      className="d-flex flex-column"
                      style={{ width: "25%" }}
                    >
                      <label className="form-label gray-label">TRENDING</label>
                      <Select
                        placeholder="Select Name"
                        className="mb-2"
                        options={trendingOptions}
                        isLoading={!trendingOptions}
                        value={{
                          value: keyContactForm.advocacyTrending,
                          label: getTrendingName(
                            keyContactForm.advocacyTrending
                          ),
                        }}
                        onChange={(rlt: any) => {
                          setKeyContactRelationshipLevelTrendId({
                            value: rlt.value,
                            label: rlt.label,
                          });
                          handleFormChange(
                            rlt.value,
                            "relationshipLevelTrendId"
                          );
                        }}
                        isDisabled={isNotEditable()}
                      />
                    </div>
                  </div>
                  <div className="advocacy-description mt-5">
                    <p className="grayMedium">
                      {setAdvocacyDescription(keyContactForm.advocacyLevel)}
                    </p>
                    <p className="form-label gray-label">Behaviors Exhibited</p>
                    <ul className="list-item">
                      {setAdvocacyTexts(keyContactForm.advocacyLevel).map(
                        (elem: string) => (
                          <li key={elem}>{elem}</li>
                        )
                      )}
                    </ul>
                  </div>
                  <div className="d-flex flex-column mt-3">
                    <label className="form-label gray-label">
                      LAST VERIFIED ON
                    </label>
                    <div style={{ width: "30%" }}>
                      <Calendar
                        date={keyContactForm.advocacyLastVerified}
                        isDisabled={isNotEditable()}
                        onChange={(e) =>
                          handleFormChange(e, "advocacyLastVerified")
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Relationship Growth Plan */}
              <div className="block mt-5">
                <div className="block-header block-header-with-background">
                  <h6 style={{ fontWeight: "bold" }}>
                    Relationship Growth Plan
                  </h6>
                  <div>
                    Enter the plan to grow the relationship with this contact.
                  </div>
                </div>
                <div className="block-body">
                  <div className="d-flex flex-column mb-3">
                    <label className="form-label gray-label">
                      Behaviors and Concerns
                    </label>
                    <TextareaAutosize
                      minRows={3}
                      maxRows={6}
                      className="textarea-resize"
                      value={keyContactForm.behaviorsAndConcerns}
                      disabled={isNotEditable()}
                      onChange={(e) =>
                        handleFormChange(e, "behaviorsAndConcerns")
                      }
                      nonce={undefined}
                      onResize={undefined}
                      onResizeCapture={undefined}
                    />
                  </div>
                  <div className="d-flex flex-column">
                    <label className="form-label gray-label">
                      RELATIONSHIP GROWTH PLAN
                    </label>
                    <TextareaAutosize
                      minRows={3}
                      maxRows={6}
                      className="textarea-resize"
                      value={keyContactForm.relationshipGrowthPlan}
                      disabled={isNotEditable()}
                      onChange={(e) =>
                        handleFormChange(e, "relationshipGrowthPlan")
                      }
                      nonce={undefined}
                      onResize={undefined}
                      onResizeCapture={undefined}
                    />
                  </div>
                  <div className="d-flex flex-column mt-3">
                    <label className="form-label gray-label">
                      LAST VERIFIED ON
                    </label>
                    <div style={{ width: "40%" }}>
                      <Calendar
                        date={keyContactForm.relationshipLastVerified}
                        isDisabled={isNotEditable()}
                        onChange={(e) =>
                          handleFormChange(e, "relationshipLastVerified")
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Stakeholder Confidence */}
              <div className="block mt-5">
                <div className="block-header block-header-with-background">
                  <h6 style={{ fontWeight: "bold" }}>Stakeholder Confidence</h6>
                  <div>
                    Direct client stakeholder confidence in the team’s
                    performance. Should be a quick live conversation and when
                    that isn’t possible then an email with a requested response
                    time
                  </div>
                </div>
                <div className="block-body">
                  <div className="d-flex flex-column">
                    <label className="form-label gray-label">LEVEL</label>
                    <Slider
                      key="stackholder-slider"
                      disabled={isNotEditable()}
                      style={{ width: "95%", margin: "auto" }}
                      value={
                        keyContactForm.stakeholderConfidence
                          ? keyContactForm.stakeholderConfidence
                          : 0
                      }
                      step={1}
                      min={0}
                      max={10}
                      onChange={(e) =>
                        handleFormChange(e, "stakeholderConfidence")
                      }
                    />
                    <div
                      className="slider-indicators"
                      style={{ width: "100%" }}
                    >
                      <span>N/A</span>
                      <span>1</span>
                      <span>2</span>
                      <span>3</span>
                      <span>4</span>
                      <span>5</span>
                      <span>6</span>
                      <span>7</span>
                      <span>8</span>
                      <span>9</span>
                      <span>10</span>
                    </div>
                    <div className="d-flex flex-column mt-3">
                      <label className="form-label gray-label">
                        CLIENT COMMENT
                        {keyContactForm.stakeholderClientComment.length === 0 &&
                        formFields.stakeholderConfidence ? (
                          <span style={{ color: "red" }}> *</span>
                        ) : (
                          ""
                        )}
                      </label>
                      <TextareaAutosize
                        minRows={3}
                        maxRows={6}
                        className="textarea-resize"
                        value={keyContactForm.stakeholderClientComment}
                        disabled={isNotEditable()}
                        onChange={(e) =>
                          handleFormChange(e, "stakeholderClientComment")
                        }
                        nonce={undefined}
                        onResize={undefined}
                        onResizeCapture={undefined}
                      />
                    </div>
                    <div className="d-flex mt-3 justify-content-between">
                      <div style={{ width: "30%" }} className="me-3">
                        <label className="form-label gray-label">
                          LAST VERIFIED ON
                        </label>
                        <Calendar
                          date={keyContactForm.stakeholderLastVerified}
                          isDisabled={isNotEditable()}
                          onChange={(e) =>
                            handleFormChange(e, "stakeholderLastVerified")
                          }
                        />
                      </div>
                      <div style={{ width: "30%" }}>
                        <label className="form-label gray-label">
                          LAST VERIFIED BY
                        </label>
                        <Select
                          placeholder="Select Name"
                          className="mb-2"
                          options={users}
                          isLoading={isLoadingExternalBilling}
                          value={{
                            value: keyContactForm.stakeholderLastVerifiedBy,
                            label: getUserNameToCompleteSelect(
                              users,
                              keyContactForm.stakeholderLastVerifiedBy
                            ),
                          }}
                          onChange={(lvb: any) => {
                            setLastVerifiedByUser({
                              value: lvb.value,
                              label: lvb.label,
                            });
                            handleFormChange(
                              lvb.value,
                              "stakeholderLastVerifiedBy"
                            );
                          }}
                          isDisabled={isNotEditable()}
                        />
                      </div>
                      {!keyContact.confidenceHistory && (
                        <div className="link-text mt-4"></div>
                      )}
                      {keyContact.confidenceHistory && (
                        <Link
                          className="link-text mt-4"
                          to={confidenceHistoryLink}
                        >
                          CONFIDENCE HISTORY
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <Footer />
          </div>
        )}
      </>
    </>
  );
};
