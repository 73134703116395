import { useDispatch, useSelector } from "react-redux";
import {
  IsLoading,
  loadTimeEntriesInfo,
  selectOrderedBy,
  setLoading,
} from "../redux/reducers/approvalTime.reducer";
import { selectMetaTimesData } from "../redux/reducers/metaTimes.reducer";
import { useCallback, useEffect } from "react";
import { TimesheetsService } from "../services/timesheets";
import {
  hasSuperTimeAdminAccess,
  hasTimeAdminAccess,
  isSupervisorOrProjectOwner,
  preparedFiltersToApprove,
} from "../utils/timesUtils";
import { actualUIState } from "../../SharedModule/redux/reducers/uiShared.reducer";

export const useApprovalTime = (filters: any, isLoadingUserInfo: boolean) => {
  const dispatch = useDispatch();

  const isLoading = useSelector(IsLoading);
  const MetaTimesInfo = useSelector(selectMetaTimesData);
  const { userList } = useSelector(selectOrderedBy);

  // times module admin
  const { userRolesTimesModule } = useSelector(actualUIState);
  const isTimeAdmin = hasTimeAdminAccess(userRolesTimesModule);
  const isSuperTimeAdmin = hasSuperTimeAdminAccess(userRolesTimesModule);
  const isSupervisorOrPO = isSupervisorOrProjectOwner(userRolesTimesModule);

  const filtersForRequest = preparedFiltersToApprove(
    filters,
    isSuperTimeAdmin,
    isSupervisorOrPO,
    MetaTimesInfo
  );

  const loadTimeEntriesToApprove = useCallback(async () => {
    dispatch(setLoading(true));
    if (!isLoadingUserInfo) {
      const results = await TimesheetsService.getTimeEntriesToApproveByUsers(
        filtersForRequest
      );
      dispatch(loadTimeEntriesInfo(results.data));
    }
  }, [dispatch, filters, isLoadingUserInfo]);

  useEffect(() => {
    loadTimeEntriesToApprove();
  }, [loadTimeEntriesToApprove]);

  return {
    isLoading,
    MetaTimesInfo,
    filters,
    userList,
    isTimeAdmin,
    loadTimeEntriesToApprove,
  };
};
