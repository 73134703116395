import { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { BiLeftArrowAlt } from "react-icons/bi";
import { MdArrowRightAlt } from "react-icons/md";
import {
  ROUTE_PRODUCT_KEYCONTACTS,
  ROUTE_PRODUCT_KEYCONTACTS_DETAILS,
} from "../../SharedModule/utils/constants";
import { Select } from "../../SharedModule/components/Select";
import { KeyContactsService } from "./../services/keyContacts";
import { useModal } from "../../SharedModule/hooks/useModal";
import { useKeyContacts } from "../hooks/useKeyContacts";
import { ExternalService } from "../services/external";
import { Spinner } from "../../SharedModule/components/Spinner";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { setActiveTab } from "../../SharedModule/redux/reducers/uiShared.reducer";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../../SharedModule/components/Footer";
import { selectAuth } from "../../SharedModule/redux/reducers/auth.reducer";

export const NewKeyContactPage = () => {
  let { productId }: any = useParams();

  const { trackPageView } = useMatomo();

  const auth = useSelector(selectAuth);
  const isLogged = auth.isAuthenticated;

  const [actualCompanyId, setActualCompanyId] = useState(0);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { keyContactList, isContentEditable, isProductActive } = useKeyContacts(
    Number(productId)
  );

  const [loadingContacts, setLoadingContacts] = useState(true);

  const [actualContactsToAdd, setActualContactsToAdd] = useState<any>([]);

  const loadContacts = async () => {
    let serviceContacts = await ExternalService.getContacts(Number(productId));

    // Set companyId to create endpoint if some users is not appears in contacts list already
    setActualCompanyId(serviceContacts.companyId);

    let newActualContactsToAdd = serviceContacts.aSuiteContacts.map(
      (contact) => ({
        value: contact.id,
        label: contact.firstName + " " + contact.lastName,
      })
    );
    setActualContactsToAdd(newActualContactsToAdd);
    setLoadingContacts(false);
  };

  useEffect(() => {
    // matomo page tracker
    trackPageView({
      documentTitle: document.location.hostname + "/" + document.title,
    });
    dispatch(setActiveTab("keyContacts"));

    loadContacts();
  }, []);

  if (keyContactList) {
    keyContactList.forEach((elem: any) => {
      let keyContactAddedIndex = actualContactsToAdd.findIndex(
        (actual: any) => actual.value === elem.contactId
      );
      if (keyContactAddedIndex >= 0) {
        actualContactsToAdd.splice(keyContactAddedIndex, 1);
      }
    });
  }

  const { showModal, closeModal, Modal, ...modalProps } = useModal();

  const [keyContact, setKeyContact] = useState({
    value: "0",
    label: "-- Select Name --",
  });

  const handleConfirmContact = async () => {
    let result: any = await KeyContactsService.addKeyContact(
      productId,
      Number(keyContact.value)
    );
    navigate(
      ROUTE_PRODUCT_KEYCONTACTS_DETAILS.replace(
        ":productId",
        productId
      ).replace(":contactId", result.keyContactId.toString())
    );
  };

  const handleCancelContact = async () => {
    navigate(ROUTE_PRODUCT_KEYCONTACTS.replace(":productId", productId));
  };

  return (
    <>
      <Modal closeModal={closeModal} {...modalProps} />
      <div className="content" style={{ marginTop: "-15px" }}>
        <div className="sticky-row">
          <div
            className="button-header"
            style={{ width: "100%", padding: "15px" }}
          >
            <Link
              className="back-button-container d-flex text-decoration-none"
              to={ROUTE_PRODUCT_KEYCONTACTS.replace(":productId", productId)}
            >
              <BiLeftArrowAlt className="back-button-arrow mt-1" />
              <span className="back-button-text mt-1">Back to Contacts</span>
            </Link>
            <div>
              {isLogged && isContentEditable && isProductActive && (
                <button
                  className="btn btn-primary me-3"
                  onClick={() => handleConfirmContact()}
                >
                  Add Contact
                </button>
              )}
              <button
                className="btn button-secondary"
                onClick={() => handleCancelContact()}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
        {loadingContacts && (
          <Spinner
            style={{
              marginLeft: "50%",
              marginTop: "5%",
            }}
          />
        )}
        {!loadingContacts && (
          <div className="d-flex">
            <div className="block mt-4">
              <div className="block-header">
                <h6 style={{ fontWeight: "bold" }}>Contact Details</h6>
                <div className="d-flex">
                  If you can't find the contact you're looking for,
                  <a
                    className="link-text ms-1"
                    target="_blank"
                    rel="noreferrer"
                    href={`${process.env.REACT_APP_ASUITE_BASE_URI}/busdev/busdevcontacts.asp?contacts=0&company=${actualCompanyId}`}
                  >
                    add it to Business Development first.
                    <MdArrowRightAlt />
                  </a>
                </div>
              </div>
              <div className="block-body">
                <div className="d-flex justify-content-between">
                  <div className="position-relative" style={{ width: "40%" }}>
                    <label className="form-label gray-label">FULL NAME</label>
                    <Select
                      placeholder="Select Name"
                      className="mb-2"
                      options={actualContactsToAdd}
                      isLoading={!actualContactsToAdd}
                      value={{
                        value: keyContact.value,
                        label: keyContact.label,
                      }}
                      onChange={(kc: any) => {
                        setKeyContact({
                          value: kc.value,
                          label: kc.label,
                        });
                      }}
                      isDisabled={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <Footer />
      </div>
    </>
  );
};
