import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Calendar } from "../../SharedModule/components/Calendar";
import { selectBillingCycles } from "../redux/reducers/metaBilling.reducer";
import "../../styles/main.module.scss";
import { AdvancedFilters } from "../components/ProcessBillableItems/AdvancedFilters";
import { FiltersSelect } from "../components/ProcessBillableItems/AdvancedFiltersSelect";
import { Checkbox } from "../../SharedModule/components/Checkbox";
import {
  selectFilters,
  selectEndmonthDate,
  selectMidmonthDate,
  setItem,
  billableItemType,
  toggleSelectedCategory,
  resetFilters as resetFiltersAction,
} from "../redux/reducers/filters.reducer";
import { clear as clearBillableHours } from "../redux/reducers/billableHours.reducer";
import { clearBillableItems } from "../redux/reducers/billableItems.reducer";
import {
  ROUTE_BILLABLE_ITEMS_RESULTS,
  ROUTE_PROFFESSIONAL_SERVICES_QUERY,
} from "../../SharedModule/utils/constants";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { setActiveTab } from "../../SharedModule/redux/reducers/uiShared.reducer";
import Footer from "../../SharedModule/components/Footer";

enum periods {
  MIDMONTH,
  MONTHEND,
}

export const ProfessionalServicesQuery = () => {
  const dispatch = useDispatch();
  const billingCycles = useSelector(selectBillingCycles);
  const filtersState = useSelector(selectFilters);

  const navigate = useNavigate();
  const location = useLocation();
  const { trackPageView } = useMatomo();

  const changePeriod = (period: number) => {
    if (period === periods.MIDMONTH) {
      dispatch(selectMidmonthDate());
    } else {
      dispatch(selectEndmonthDate());
    }
  };

  const resetFilters = useCallback(
    (event: any) => {
      event && event.preventDefault();
      dispatch(resetFiltersAction());
    },
    [dispatch]
  );

  useEffect(() => {
    // matomo page tracker
    trackPageView({
      documentTitle: document.location.hostname + "/" + document.title,
    });
  }, []);

  useEffect(() => {
    if (location.hash !== "#keep") {
      resetFilters(null);
    }

    dispatch(setActiveTab(ROUTE_PROFFESSIONAL_SERVICES_QUERY));
  }, [location.hash, resetFilters]);

  const isDisabled = filtersState.selectedCategories.length <= 0;

  const runQuery = async (event: any) => {
    event.preventDefault();
    if (isDisabled) return;
    dispatch(clearBillableHours());
    dispatch(clearBillableItems());
    navigate(ROUTE_BILLABLE_ITEMS_RESULTS, {
      state: { from: ROUTE_PROFFESSIONAL_SERVICES_QUERY },
    });
  };

  return (
    <div className="content">
      <div className="content-header" style={{ marginTop: "0" }}>
        <h2>Process Professional Services</h2>
      </div>
      <form className="card invoice-summary-background p-4 mt-3">
        <div className="row justify-content-center">
          <label className="col-sm-3 col-form-label">Billing period</label>
          <div className="col-sm-5">
            <div className="d-flex">
              <div className="position-relative">
                <label className="form-label">From</label>
                <Calendar
                  date={filtersState.dateFrom}
                  onChange={(date: string) =>
                    dispatch(setItem({ dateFrom: date.toString() }))
                  }
                />
              </div>
              <div className="position-relative ms-5">
                <label className="form-label">To</label>
                <Calendar
                  date={filtersState.dateTo}
                  onChange={(date: string) =>
                    dispatch(setItem({ dateTo: date.toString() }))
                  }
                />
              </div>
            </div>
            <div className="mt-2">
              <div
                className="btn button-secondary"
                onClick={() => changePeriod(periods.MIDMONTH)}
              >
                Mid Month
              </div>
              <div
                className="btn button-secondary ms-2"
                onClick={() => changePeriod(periods.MONTHEND)}
              >
                Month's End
              </div>
            </div>
          </div>
        </div>
        <FiltersSelect
          className="row justify-content-center mt-4"
          label="Billing cycles"
          options={billingCycles}
          value={filtersState.billingCycles}
          isLoading={!billingCycles}
          onChange={(billingCycle) => {
            // Needs to be converted to null instead of setting an empty array
            dispatch(
              setItem({
                billingCycles: billingCycle.length ? billingCycle : null,
              })
            );
          }}
          isDisabled={false}
        />
        <div className="row justify-content-center mt-4">
          <label className="col-sm-3 col-form-label">Include</label>
          <div className="col-sm-5">
            <Checkbox
              label="Billable hours"
              value={
                filtersState.selectedCategories.indexOf(
                  billableItemType.billableHours
                ) !== -1
              }
              onClickHandler={() =>
                dispatch(toggleSelectedCategory(billableItemType.billableHours))
              }
            />
            <Checkbox
              className="mt-2"
              label="Resources"
              value={
                filtersState.selectedCategories.indexOf(
                  billableItemType.resources
                ) !== -1
              }
              onClickHandler={() =>
                dispatch(toggleSelectedCategory(billableItemType.resources))
              }
            />
            <Checkbox
              className="mt-2"
              label="Billing milestones"
              value={
                filtersState.selectedCategories.indexOf(
                  billableItemType.billingMilestones
                ) !== -1
              }
              onClickHandler={() =>
                dispatch(
                  toggleSelectedCategory(billableItemType.billingMilestones)
                )
              }
            />
          </div>
        </div>
        <div className="row justify-content-center mt-4">
          <label className="col-sm-3 col-form-label">Other</label>
          <div className="col-sm-5">
            <Checkbox
              label="Include work orders attached to a project."
              value={filtersState.includeWO}
              onClickHandler={() =>
                dispatch(setItem({ includeWO: !filtersState.includeWO }))
              }
            />
          </div>
        </div>
        <AdvancedFilters />
        <hr className="separator" />
        <div className="mt-4 footer-filter">
          <div className="link-text" onClick={resetFilters}>
            Reset Filters
          </div>
          <button
            className={`btn btn-primary ${isDisabled ? "disabled" : ""}`}
            onClick={runQuery}
          >
            Run Query
          </button>
        </div>
      </form>
      <Footer />
    </div>
  );
};
