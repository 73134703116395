import { useEffect, useRef, useState } from "react";
import { Product } from "../types/types";
import { ModalLayout } from "../components/ModalLayout";
import { ModalPortal } from "../../SharedModule/components/ModalPortal";
import { useNavigate } from "react-router-dom";
import {
  PRODUCTS_CREATOR,
  ROUTE_PRODUCTS_LIST,
  ROUTE_PRODUCT_SPOTLIGHT,
} from "../../SharedModule/utils/constants";
import { useProducts } from "../hooks/useProducts";
import { Spinner } from "../../SharedModule/components/Spinner";
import { useDispatch, useSelector } from "react-redux";
import { selectRecurringOptions } from "../redux/reducers/metaProducts.reducer";
import { setShowActives } from "../redux/reducers/products.reducer";
import { useModal } from "../../SharedModule/hooks/useModal";
import { useDeleteProduct } from "../hooks/useDeleteProduct";
import { AccessControl } from "../../SharedModule/components/AccessControl";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { ModalDelete } from "../components/ModalDelete";
import { ProductsService } from "../services/products";
import { addToast } from "../../SharedModule/redux/reducers/ui.reducer";
import { setIsLoadingData as setIsLoadingDataSpotlight } from "../redux/reducers/spotlight.reducer";
import { setIsLoadingData as setIsLoadingDataKeyContact } from "../redux/reducers/keyContact.reducer";
import { setIsLoadingData as setIsLoadingDataSuccessCriteria } from "../redux/reducers/successCriteria.reducer";
import eventHandler from "../../SharedModule/utils/eventHandler";
import { TbEdit, TbTrash } from "react-icons/tb";
import { MdFileDownloadDone, MdDoNotDisturb } from "react-icons/md";
import { setActiveTab } from "../../SharedModule/redux/reducers/uiShared.reducer";
import Footer from "../../SharedModule/components/Footer";

const emptyProduct = {
  id: 0,
  name: "",
  account: { id: 0, name: "", client: true },
  projects: [],
};

export const ProductsListPage = () => {
  const dispatch = useDispatch();
  const [viewInactives, setViewInactives] = useState(false);
  const [productToEdit, setProductToEdit] = useState(emptyProduct);

  const [isToastClosed, setIsToastClosed] = useState(true);

  const { trackPageView } = useMatomo();

  useEffect(() => {
    // matomo page tracker
    trackPageView({
      documentTitle: document.location.hostname + "/" + document.title,
    });
    // set reducers from internal pages to true, so we avoid to see values from differents products
    dispatch(setIsLoadingDataSpotlight(true));
    dispatch(setIsLoadingDataKeyContact(true));
    dispatch(setIsLoadingDataSuccessCriteria(true));

    dispatch(setActiveTab(ROUTE_PRODUCTS_LIST));

    eventHandler.on("toast action", (data) => {
      if (data.isClosed) {
        setIsToastClosed(true);
      } else {
        setIsToastClosed(false);
      }
    });

    return () => {
      eventHandler.remove("toast action", null);
    };
  }, []);

  const {
    products,
    isLoadingProducts,
    isShowingActives,
    loadProducts,
    isLoadingExternalBilling,
    isLoadingExternalProducts,
  } = useProducts();

  const navigate = useNavigate();

  // Clean storage for spotlight state by product
  localStorage.removeItem("spotlightStateChange");

  let listProducts: Array<Product> = products.filter((prod) => {
    let check = isShowingActives ? "Active" : "Deleted";
    return prod.status.name === check;
  });

  listProducts.sort((a, b) =>
    a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()
      ? 1
      : b.name.toLocaleLowerCase() > a.name.toLocaleLowerCase()
      ? -1
      : 0
  );

  const recurringOptions = useSelector(selectRecurringOptions);

  const { showModal, closeModal, Modal, ...modalProps } = useModal();

  const deleteProduct = useDeleteProduct(
    showModal,
    closeModal,
    isShowingActives,
    loadProducts
  );

  // Modal config
  const modalRef = useRef();
  const showActionModal = () => {
    (modalRef.current as any).show(true);
  };
  const closeActionModal = (refresh: boolean) => {
    (modalRef.current as any).show(false);
    if (refresh) {
      setViewInactives(false);
      loadProducts();
    }
  };
  // Modal Delete
  let actualProductToDelete: Product;
  const modalRefDelete = useRef();
  const showModalDelete = (product: any) => {
    actualProductToDelete = product;
    (modalRefDelete.current as any).show(true);
  };
  const closeModalDelete = () => {
    (modalRefDelete.current as any).show(false);
  };

  const getActualProductToDeleteName = () => {
    return actualProductToDelete ? actualProductToDelete.name : "";
  };

  const handleProduct = async (product: any) => {
    // Return a boolean value if it's in inactive view, we should set ViewInactives to false because we reload products list
    let isInactiveView = await deleteProduct({ productId: product.id });
    if (isInactiveView) {
      setViewInactives(false);
    }
  };

  const onClickHandler = (product: any) => {
    navigate(ROUTE_PRODUCT_SPOTLIGHT.replace(":productId", product.id));
  };

  const selectClient = (account: any) => {
    let client = recurringOptions.clientsProducts?.find((client) => {
      return client.value === account.id.toString();
    });
    if (!client) {
      return account.name + " (x)";
    }
    return client?.label;
  };

  const onToggleButton = (state: boolean) => {
    setViewInactives(state);
    dispatch(setShowActives(viewInactives));
  };

  const openModal = (product: any) => {
    product ? setProductToEdit(product) : setProductToEdit(emptyProduct);
    showActionModal();
  };

  const deleteProductFromDataBase = async () => {
    let result = await ProductsService.deleteProduct(actualProductToDelete.id);
    if (result === "Product deleted successfully.") {
      dispatch(addToast({ mode: "success", message: result }));
      closeModalDelete();
      setViewInactives(false);
      loadProducts();
    } else {
      dispatch(addToast({ mode: "error", message: "Something went wrong" }));
    }
    eventHandler.dispatch("toast action", { isClosed: false });
  };

  const localSpinner = (color: string) => {
    return (
      <div className="spinner-button">
        <svg
          width="13"
          height="14"
          viewBox="0 0 13 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.38798 12.616C3.36313 12.2306 2.46328 11.5721 1.78592 10.7118C1.10856 9.85153 0.679515 8.82231 0.545268 7.73564C0.411022 6.64897 0.576691 5.54628 1.02433 4.54704C1.47197 3.54779 2.1845 2.69009 3.08475 2.06684C3.98499 1.4436 5.03862 1.07858 6.13148 1.01133C7.22435 0.944078 8.31478 1.17716 9.28464 1.68533C10.2545 2.19349 11.0668 2.95736 11.6336 3.89419C12.2004 4.83101 12.5 5.90507 12.5 7"
            stroke={color}
          />
        </svg>
      </div>
    );
  };

  return (
    <>
      <Modal closeModal={closeModal} {...modalProps} />
      <ModalPortal ref={modalRef}>
        <ModalLayout
          title="Add New Product"
          btnLabel="Add"
          btnCancelLabel="Cancel"
          btnCancel={closeActionModal}
          actionPlaceholder="notCloseOnClickOutside"
          product={productToEdit}
        />
      </ModalPortal>
      <ModalPortal ref={modalRefDelete}>
        <ModalDelete
          productName={() => getActualProductToDeleteName()}
          btnLabel="Delete"
          btnCancelLabel="Cancel"
          btnAction={() => deleteProductFromDataBase()}
          btnCancel={closeModalDelete}
          actionPlaceholder="notCloseOnClickOutside"
          dataType="product"
        />
      </ModalPortal>

      <div className="content">
        {isLoadingProducts && (
          <Spinner
            style={{
              paddingLeft: "40%",
              marginTop: "15%",
              width: "100%",
            }}
          />
        )}
        {!isLoadingProducts && (
          <>
            <div className="content-header" style={{ marginTop: "0" }}>
              <h2>
                Products
                <span className="count-amount">
                  ({listProducts.length} Products)
                </span>
              </h2>

              <div>
                <div className="btn-group" role="group">
                  <button
                    type="button"
                    onClick={() => onToggleButton(false)}
                    className={`btn btn-group-custom ${
                      viewInactives ? "btn-light" : "btn-active"
                    }`}
                    disabled={!viewInactives}
                  >
                    Active
                  </button>
                  <button
                    type="button"
                    onClick={() => onToggleButton(true)}
                    className={`btn btn-group-custom ${
                      viewInactives ? "btn-inactive" : "btn-light"
                    }`}
                    disabled={viewInactives}
                  >
                    Inactive
                  </button>
                </div>
                <AccessControl allowedRoles={PRODUCTS_CREATOR}>
                  <>
                    {(isLoadingExternalBilling ||
                      isLoadingExternalProducts ||
                      !isToastClosed) && (
                      <button className="btn btn-primary ms-5">
                        {localSpinner("white")}
                      </button>
                    )}
                    {!isLoadingExternalBilling &&
                      !isLoadingExternalProducts &&
                      isToastClosed && (
                        <button
                          className="btn btn-primary ms-5"
                          onClick={() => openModal(null)}
                        >
                          Add Product
                        </button>
                      )}
                  </>
                </AccessControl>
              </div>
            </div>

            {listProducts.length === 0 && (
              <div
                className="spotlight-disabled"
                style={{ margin: "10% auto" }}
              >
                {products.length === 0 && (
                  <h4>You are not assigned to any product</h4>
                )}
                {products.length > 0 && (
                  <h4>{`You are not assigned to any ${
                    isShowingActives ? "active" : "inactive"
                  } product`}</h4>
                )}
                <h6>
                  Ask your project manager to add you to a project that belongs
                  to the Product you want to see.
                </h6>
              </div>
            )}
            {listProducts.length > 0 && (
              <table className="table grey-table-header-background mt-4 mb-7" style={{ width: "100%" }}>
                <thead className="align-middle sticky sticky-header">
                  <tr>
                    <th className="border-dark border-top fw-600 py-0 ps-5 column-30">
                      Name
                    </th>
                    <th className="border-dark border-top fw-600 py-0 ps-5 column-30">
                      Client
                    </th>
                    <th className="border-dark border-top fw-600 py-0 ps-5 column-25">
                      Department
                    </th>
                    <th className="border-dark border-top fw-600 text-center py-0 column-20"></th>
                  </tr>
                </thead>
                <tbody>
                  {listProducts.map((product: Product) => {
                    return (
                      <tr key={product.name} className="product-link">
                        <td
                          className="ps-5 fw-500 td-shadow-effect"
                          onClick={() => onClickHandler(product)}
                        >
                          {product.name}
                        </td>
                        <td
                          className="ps-5 fw-500 td-shadow-effect"
                          onClick={() => onClickHandler(product)}
                        >
                          {selectClient(product.account)}
                        </td>
                        <td
                          className="ps-5 fw-500 td-shadow-effect"
                          onClick={() => onClickHandler(product)}
                        >
                          {product.department}
                        </td>

                        {(isLoadingExternalBilling ||
                          isLoadingExternalProducts ||
                          !isToastClosed) && (
                          <td className="iconsRow">{localSpinner("black")}</td>
                        )}
                        {!isLoadingExternalBilling &&
                          !isLoadingExternalProducts &&
                          isToastClosed && (
                            <td className="iconsRow">
                              {product.isEditable && (
                                <TbEdit
                                  size={"20px"}
                                  style={{ margin: "0 15px 5px 0" }}
                                  className="purpleHover"
                                  onClick={() => openModal(product)}
                                />
                              )}
                              {!viewInactives && product.canActivate && (
                                <MdDoNotDisturb
                                  size={"20px"}
                                  style={{ margin: "0 15px 5px 0" }}
                                  className="redHover"
                                  onClick={() => handleProduct(product)}
                                />
                              )}
                              {viewInactives && product.canActivate && (
                                <MdFileDownloadDone
                                  size={"20px"}
                                  style={{ margin: "0 15px 5px 0" }}
                                  className="purpleHover"
                                  onClick={() => handleProduct(product)}
                                />
                              )}
                              {product.isEditable && viewInactives && (
                                <AccessControl allowedRoles={PRODUCTS_CREATOR}>
                                  <TbTrash
                                    size={"20px"}
                                    style={{ margin: "0 5px 5px 0" }}
                                    className="redHover"
                                    onClick={() => showModalDelete(product)}
                                  />
                                </AccessControl>
                              )}
                            </td>
                          )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
          </>
        )}
        <Footer />
      </div>
    </>
  );
};
