import { CheckSkeleton } from "../../SharedModule/utils/checkSkeleton";
import { quantityFormat } from "../../SharedModule/utils/formatters";

export const SummaryTotals = (props) => {
  let slash = "—";

  let missingHours =
    !props.avoidCheckMissingHours &&
    props.totals !== 0 &&
    props.totals - (new Date().getDay() - 1) * 8 < 0;

  return (
    <>
      {!props.isLoading && props.showTitle && (
        <div className="summary-title">
          Summary totals:{" "}
          <span className="summary-title-value ms-1 toCapitalize">
            {props.showedWeek}
          </span>
        </div>
      )}
      <div
        className="card d-flex flex-row flex-wrap invoice-summary-background"
        style={{ marginTop: "12px" }}
      >
        <div className="flex-fill border-end" style={{ padding: "12px 24px" }}>
          <div
            className={`summary-section-title ${
              missingHours ? "text-error" : ""
            }`}
          >
            Total hours
          </div>
          <CheckSkeleton isLoading={props.isLoading} classes="fs-4 pb-1">
            <div
              className={`summary-section-number pb-1 ${
                missingHours ? "text-error" : ""
              }`}
            >
              {quantityFormat(props.totals)}
            </div>
          </CheckSkeleton>
        </div>
        <div className="flex-fill border-end" style={{ padding: "12px 24px" }}>
          <div className="summary-section-title">Billable hours</div>
          <CheckSkeleton isLoading={props.isLoading} classes="fs-4 pb-1">
            <div className="summary-section-number pb-1">
              {props.billable !== 0 ? quantityFormat(props.billable) : slash}
            </div>
          </CheckSkeleton>
        </div>
        <div className="flex-fill border-end" style={{ padding: "12px 24px" }}>
          <div className="summary-section-title">Financed hours</div>
          <CheckSkeleton isLoading={props.isLoading} classes="fs-4 pb-1">
            <div className="summary-section-number pb-1">
              {props.financed !== 0 ? quantityFormat(props.financed) : slash}
            </div>
          </CheckSkeleton>
        </div>
        <div className="flex-fill border-end" style={{ padding: "12px 24px" }}>
          <div className="summary-section-title">Non-Billable hours</div>
          <CheckSkeleton isLoading={props.isLoading} classes="fs-4 pb-1">
            <div className="summary-section-number pb-1">
              {props.nonBillable !== 0
                ? quantityFormat(props.nonBillable)
                : slash}
            </div>
          </CheckSkeleton>
        </div>
        <div className="flex-fill border-end" style={{ padding: "12px 24px" }}>
          <div className="summary-section-title">Internal hours</div>
          <CheckSkeleton isLoading={props.isLoading} classes="fs-4 pb-1">
            <div className="summary-section-number pb-1">
              {props.internal !== 0 ? quantityFormat(props.internal) : slash}
            </div>
          </CheckSkeleton>
        </div>
        <div className="flex-fill" style={{ padding: "12px 24px" }}>
          <div className="summary-section-title">Time Off hours</div>
          <CheckSkeleton isLoading={props.isLoading} classes="fs-4 pb-1">
            <div className="summary-section-number pb-1">
              {props.timeOff !== 0 ? quantityFormat(props.timeOff) : slash}
            </div>
          </CheckSkeleton>
        </div>
      </div>
    </>
  );
};
